import store from 'store'
import actions from './actions'

const STORED_USER = (storedUser) => {
  const user = {}
  Object.keys(storedUser).forEach((key) => {
    const item = store.get(`user.${key}`)
    user[key] = typeof item !== 'undefined' ? item : storedUser[key]
  })
  return user
}

export const userStateData = {
  loading: false,
  accessToken: null,
  data: null,
  staff: null,
  business: null,
  location: null,
  role: null,
  supportuser: null,
  canRegister: false,
  loggingIn: false,
  email: null,
  displayChangeEmailModal: false,
  changingEmail: false,
  canResendEmail: true,
  confirming: false,
  resendingEmail: false,

  registering: false,

  recovering: false,

  resetting: false,

  submitting: false,

  subscribing: false,

  errors: {
    register: null,
    change: null,
    login: null,
    recover: null,
    reset: null,
    business: null,
  },
}

const initialState = {
  ...STORED_USER(userStateData),
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    default:
      return state
  }
}
