import actions from './actions'

const initialState = {
  restaurantList: null,
  selectedData: null,
  restaurantdata: null,
  currentaccountmanager: null,
  res_id: null,
  billAmount: null,
  accountManagerData: null,
  loading: false,
  search: null,
  creating: false,
  updating: false,
  removing: false,
  displayAssignAccountManagerModal: false,
  displayBillAmountModal: false,
  displayBillingTypeModal: false,
  errors: {
    create: null,
    update: null,
  },
  filters: [],
  is_rotessa_option: false,
  discount_amount: 0,
  notes: [],
  website: '',
}

export default function restaurantReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_ACCOUNT_MANAGER:
      return { ...state, selectedData: action.payload, displayAssignAccountManagerModal: true }
    case actions.HIDE_ACCOUNT_MANAGER:
      return { ...state, displayAssignAccountManagerModal: false }
    case actions.SHOW_BILL_AMOUNT:
      return { ...state, selectedData: action.payload, displayBillAmountModal: true }
    case actions.HIDE_BILL_AMOUNT:
      return { ...state, displayBillAmountModal: false }
    case actions.SHOW_BILL_TYPE:
      return { ...state, selectedData: action.payload, displayBillingTypeModal: true }
    case actions.HIDE_BILL_TYPE:
      return { ...state, displayBillingTypeModal: false }
    default:
      return state
  }
}
