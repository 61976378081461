import React, { Fragment } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'

import AuthLayout from './Auth'
import BusinessLayout from './Business'
import MainLayout from './Main'
import PublicLayout from './Public'
import PageLoader from '../components/app/common/PageLoader'

const Layouts = {
  auth: AuthLayout,
  business: BusinessLayout,
  main: MainLayout,
  public: PublicLayout,
}

const mapStateToProps = ({ user, settings }) => ({ user, settings })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  const authLayouts = [
    '/register',
    '/confirm-email',
    '/login',
    '/recover-password',
    '/reset-password',
    '/lockscreen',
    '/404',
    '/500',
  ]

  const businessLayouts = ['/business']

  const publicLayouts = ['/public']

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/' || publicLayouts.includes(pathname)) {
      return 'public'
    }
    if (authLayouts.includes(pathname)) {
      return 'auth'
    }
    if (businessLayouts.includes(pathname)) {
      return 'business'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.accessToken
  const isUserLoading = user.loading

  const isAuthLayout = getLayout() === 'auth'
  const isBusinessLayout = getLayout() === 'business'
  const isPublicLayout = getLayout() === 'public'

  const restrictedAuthRoutes = ['/confirm-email']
  const isAuthRouteRestricted = restrictedAuthRoutes.includes(pathname) && user.email == null

  const isBusinessCreated = user.business != null && user.location != null
  // const isBusinessCreated = true

  const BootstrappedLayout = () => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading) {
      return (
        <PublicLayout>
          <PageLoader />
        </PublicLayout>
      )
    }
    // redirect to login page if current is not login page and user not authorized and user does not initiate confirm email
    if (
      (!isPublicLayout && !isAuthLayout && !isUserAuthorized) ||
      (!isBusinessCreated && !isAuthLayout && !isUserAuthorized) ||
      (isAuthLayout && isAuthRouteRestricted)
    ) {
      return <Redirect to="/login" />
    }
    // redirect to business page if user is logged in and doesn't have any business/location
    // if (isUserAuthorized && !isBusinessCreated && !isBusinessLayout) {
    //   return <Redirect to="/business" />
    // }
    if (isUserAuthorized && isBusinessCreated && (isAuthLayout || isBusinessLayout)) {
      return <Redirect to="/" />
    }
    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet titleTemplate="Plento | %s" title="Plento" />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
