const actions = {
  SET_STATE: 'restaurant/SET_STATE',
  SHOW_ACCOUNT_MANAGER: 'restaurant/SHOW_ACCOUNT_MANAGER',
  SHOW_BILL_AMOUNT: 'restaurant/SHOW_BILL_AMOUNT',
  HIDE_ACCOUNT_MANAGER: 'restaurant/HIDE_ACCOUNT_MANAGER',
  HIDE_BILL_AMOUNT: 'restaurant/HIDE_BILL_AMOUNT',
  ASSIGNNOW: 'restaurant/ASSIGNNOW',
  CHANGEBILLAMOUNT: 'restaurant/CHANGEBILLAMOUNT',
  LOAD_ACCOUNT_MANAGER: 'restaurant/LOAD_ACCOUNT_MANAGER',
  SHOW_BILL_TYPE: 'restaurant/SHOW_BILL_TYPE',
  HIDE_BILL_TYPE: 'restaurant/HIDE_BILL_TYPE',
  LOAD_ALL: 'restaurant/LOAD_ALL',
  CHANGEBILLTYPE: 'restaurant/CHANGEBILLTYPE',
  ADD_NOTES: 'restaurant/ADD_NOTES',
}

export default actions
