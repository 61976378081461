export default async function getUserMenuData() {
  return [
    {
      title: 'Manage Restaurants',
      key: 'manage-restaurants',
      icon: 'fe fe-clipboard',
      url: '/manage-restaurants',
      divider: true,
    },
    {
      title: 'Manage Users',
      key: 'manage-user',
      icon: 'fe fe-user',
      url: '/manage-users',
      divider: true,
    },
  ]
}
