import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'

const Loading = () => {
  const [height, setHeight] = useState(window.innerHeight - 100);
  useEffect(() => {
    setHeight(window.innerHeight - 100)
    window.addEventListener('resize', () =>{
      setHeight(window.innerHeight - 100)
    })
  }, [])

  return (
    <div style={{ position: "absolute", width: "100%", height: "100%", background: "rgba(255,255,255,0.4)", marginLeft: "-28px", zIndex: 999999999, perspective: "1000px" }}>
      <div style={{ width: "100%", height, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Spin style={{ marginTop: "-50px" }} indicator={<LoadingOutlined color="#262626" style={{ fontSize: 45 }} spin />} />
      </div>
    </div>
  )
}

export default Loading
