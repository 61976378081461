import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadAll,
  assignaccmanager,
  loadaccountmanager,
  changebill,
  changebillplan,
  addnotes,
} from 'api/restaurant'
import actions from './actions'

export function* LOAD_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadAll)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { businesses } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        restaurantList: businesses,
      },
    })
  }
}

export function* LOAD_ACCOUNT_MANAGER() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadaccountmanager)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { support_users } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        accountManagerData: support_users,
      },
    })
  }
}

export function* ASSIGNNOW({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response, error } = yield call(assignaccmanager, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_ACCOUNT_MANAGER,
    })
    yield call(LOAD_ALL)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        create: errors,
      },
    })
  }
}

export function* CHANGEBILLAMOUNT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response, error } = yield call(changebill, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_BILL_AMOUNT,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        discount_amount: payload.discount_amount,
      },
    })
    yield call(LOAD_ALL)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        create: errors,
      },
    })
  }
}

export function* CHANGEBILLTYPE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response, error } = yield call(changebillplan, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_BILL_TYPE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        is_rotessa_option: payload.is_rotessa_option,
      },
    })
    yield call(LOAD_ALL)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        create: errors,
      },
    })
  }
}

export function* ADD_NOTES({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response, error } = yield call(addnotes, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        notes: response?.data?.notes || [],
      },
    })
    yield call(LOAD_ALL)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        create: errors,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LOAD_ACCOUNT_MANAGER, LOAD_ACCOUNT_MANAGER),
    takeLatest(actions.ASSIGNNOW, ASSIGNNOW),
    takeLatest(actions.CHANGEBILLAMOUNT, CHANGEBILLAMOUNT),
    takeLatest(actions.CHANGEBILLTYPE, CHANGEBILLTYPE),
    takeLatest(actions.ADD_NOTES, ADD_NOTES),
  ])
}
