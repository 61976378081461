import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { find } from 'lodash'
import style from './style.module.scss'
import UserMenu from '../../TopBar/UserMenu'

const mapStateToProps = ({ menu, settings, user }) => ({
  menuData: menu?.menuData,
  appName: settings?.appName,
  isMenuCollapsed: settings?.isMenuCollapsed,
  isMobileView: settings?.isMobileView,
  isMenuUnfixed: settings?.isMenuUnfixed,
  isMenuShadow: settings?.isMenuShadow,
  leftMenuWidth: settings?.leftMenuWidth,
  menuColor: settings?.menuColor,
  logo: settings?.logo,
  role: user?.role,
  userId: user?.id,
  // onboardStatus: user.onboard_status || {},
  businessCode: user?.business?.code,
})

const MenuLeft = ({
  dispatch,
  menuData = [],
  location: { pathname },

  appName,
  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  role,
  businessCode,
  userId,
  // onboardStatus,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  useEffect(() => {
    applySelectedKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const selectedItem = find(flattenItems(menuData), ['url', pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }

  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = (keys) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = (e) => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }

  const generateSubmenu = (items) =>
    items.map((menuItem) => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })

  const generateItem = (item) => {
    const { key, title, url, icon, count } = item
    let { disabled } = item
    if (!businessCode && userId && !(item.key === 'getting_started' || item.key === 'business')) {
      disabled = true
    }
    if (item.category) {
      return <Menu.ItemGroup key={Math.random()} title={item.title} />
    }
    if (item.url) {
      return (
        true && (
          <Menu.Item
            key={key}
            className={item.divider && !isMenuCollapsed && style.menuItem}
            disabled={disabled}
            style={
              item.divider && {
                display: 'flex',
                alignItems: 'center',
                borderRadius: isMenuCollapsed ? '5px' : 0,
                borderBottom: '0px !important',
                marginTop: '10px',
                marginBottom: '10px',
                height: isMenuCollapsed ? '45px' : '50px',
              }
            }
          >
            <span className="" />
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                <span className={style.title}>{title}</span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
              </a>
            )}
            {!item.target && (
              <Link to={url}>
                <span className={style.title}>
                  {title}
                  {/* {key === 'getting_started' &&
                    ` (${onBoardCount.completed}/${onBoardCount.total})`
                    } */}
                </span>
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && (
                  <span
                    style={item.divider && { top: '16px' }}
                    className={`${icon} ${style.icon} icon-collapsed-hidden`}
                  />
                )}
              </Link>
            )}
          </Menu.Item>
        )
      )
    }
    return (
      <Menu.Item key={key} disabled={disabled}>
        <span className={style.title}>{title}</span>
        {count && <span className="badge badge-success ml-2">{count}</span>}
        {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
      </Menu.Item>
    )
  }

  const generateMenuItems = () => {
    return menuData.map((menuItem) => {
      if (menuItem.setBottom) return null
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )
        return (
          <Menu.SubMenu
            className={menuItem.key === 'settings' && 'set-bottom'}
            title={subMenuTitle}
            key={menuItem.key}
          >
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return generateItem(menuItem)
    })
  }

  const generateBottomMenuItems = () => {
    return menuData.map((menuItem) => {
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={style.title}>{menuItem.title}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
          </span>
        )
        return (
          <Menu.SubMenu
            className={menuItem.key === 'settings' && 'set-bottom'}
            title={subMenuTitle}
            key={menuItem.key}
          >
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        )
      }
      return null
    })
  }

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      }

  return (
    <Layout.Sider
      {...menuSettings}
      className={classNames(`${style.menu}`, {
        [style.white]: menuColor === 'white',
        [style.gray]: menuColor === 'gray',
        [style.dark]: menuColor === 'dark',
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
        }}
      >
        <div className={style.logoContainer}>
          <div className={style.logo}>
            {!isMenuCollapsed && (
              <img src="resources/images/logo.svg" className="mr-2 logo-img" alt={appName} />
            )}
            {isMenuCollapsed && (
              <img
                src="resources/images/logo-only.png"
                style={{ width: '36px', height: '36px' }}
                className="mr-2"
                alt={appName}
              />
            )}
            <div className={style.name}>{logo}</div>
          </div>
        </div>
        <PerfectScrollbar className={style.scrollContainer}>
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateMenuItems()}
          </Menu>
          <div className={style.marginAuto} />
          <Menu
            onClick={handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            className={style.navigation}
            inlineIndent="15"
          >
            {generateBottomMenuItems()}
          </Menu>
          <div
            className={[
              style.bottomMenuItem,
              !isMenuCollapsed ? style.alignLeft : 'm-collapsed',
            ].join(' ')}
          >
            <div
              className={[style.collapseBar, 'collapse-bar-block'].join(' ')}
              aria-hidden="true"
              onKeyDown={() => {}}
              onClick={onCollapse}
            >
              {isMenuCollapsed && (
                <span style={{ fontSize: '1.2625rem' }} className="fe fe-chevron-right" />
              )}
              {!isMenuCollapsed && (
                <span
                  style={{ fontSize: '1.2625rem', paddingLeft: '6px' }}
                  className="fe fe-chevron-left"
                />
              )}
              {!isMenuCollapsed && <span style={{ paddingLeft: '6px' }}>Collapse sidebar</span>}
            </div>
            <div className={style.userMenu}>
              <UserMenu />
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  )
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
