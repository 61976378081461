import { all, call, put, takeLatest } from 'redux-saga/effects'
import { history } from 'index'
import { login, recoverPassword, resetPassword } from 'api/user'
import store from 'store'
import MenuAction from 'redux/menu/actions'
import RootAction from 'redux/actions'
import actions from './actions'
import { userStateData } from './reducers'

function* HANDLE_USER_TOKEN(response) {
  console.log('response', response)
  const useraccess_token = response?.data?.access_token
  const userLoggedIn = response?.data?.support_user?.name
  const userRoleLoggedIn = response?.data?.support_user?.role
  store.set(`user.accessToken`, response?.data?.access_token)
  store.set(`user.supportuser`, response?.data?.support_user?.name)
  store.set(`user.role`, response?.data?.support_user?.role)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken: useraccess_token,
      role: userRoleLoggedIn,
      supportuser: userLoggedIn,
    },
  })
  yield put({
    type: MenuAction.SET_DATA,
  })
  yield history.push('/')
}

export function* LOGIN({ payload }) {
  payload.device_id = '12:11:12:11:12:11:12'
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loggingIn: false,
    },
  })
  if (response) {
    // const { access_token } = response.data
    yield call(HANDLE_USER_TOKEN, response)
  }
}

export function* RECOVER_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: true,
    },
  })
  const { response, error } = yield call(recoverPassword, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: false,
    },
  })
  if (response) {
    yield history.push('/')
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        recover: errors,
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: true,
    },
  })
  const { response, error } = yield call(resetPassword, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: false,
    },
  })
  if (response) {
    const { access_token } = response.data
    yield call(HANDLE_USER_TOKEN, access_token)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        reset: errors,
      },
    })
  }
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: RootAction.CLEAR_STATE,
  })
  yield put({
    type: actions.SET_STATE,
    payload: userStateData,
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.RECOVER_PASSWORD, RECOVER_PASSWORD),
    takeLatest(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),
  ])
}
