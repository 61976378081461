import apiClient from 'services/axios'

const BASE_URL = 'support-user'

export async function login(payload) {
  return apiClient
    .post(`${BASE_URL}/login`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function recoverPassword(payload) {
  return apiClient
    .post(`${BASE_URL}/recover`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function resetPassword(payload) {
  return apiClient
    .post(`${BASE_URL}/reset`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function logout() {
  return apiClient
    .delete(`${BASE_URL}/logout`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
