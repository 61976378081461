import { all } from 'redux-saga/effects'
import menu from './menu/sagas'
import user from './user/sagas'
import staff from './staff/sagas'
import settings from './settings/sagas'
import restaurant from './restaurant/sagas'

export default function* rootSaga() {
  yield all([
    menu(),
    user(),
    staff(),
    settings(),
    restaurant(),
  ])
}
