import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import $ from 'jquery'
import style from './style.module.scss'

import bg from '../../../public/resources/images/bg-banner-1.jpg'
import bg2 from '../../../public/resources/images/bg-banner-2.jpg'
import bg3 from '../../../public/resources/images/bg-banner-3.jpg'
import bg4 from '../../../public/resources/images/bg-banner-4.jpg'
import bg5 from '../../../public/resources/images/bg-banner-5.jpg'
import bg6 from '../../../public/resources/images/bg-banner-6.jpg'

const mapStateToProps = ({ settings }) => ({
  appName: settings.appName,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  appName,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  intl: { formatMessage },
}) => {
  const [bgImage, setBgImage] = useState(bg)
  let counter = 1

  useEffect(() => {
    const images = [bg, bg2, bg3, bg4, bg5, bg6]
    // eslint-disable-next-line
    counter = 1
    console.log('images', images, counter, setBgImage)
    setInterval(() => {
      counter %= 6
      setBgImage(images[counter])
      counter += 1
      $('.bg-image').removeClass('fade-in-image')
      setTimeout(() => {
        $('.bg-image').addClass('fade-in-image')
      }, 50)
    }, 5000)
  }, [])

  return (
    <Layout className="vb__layout">
      <Layout.Content>
        <div
          // style={{ background: "url(resources/images/banner.png)" }}
          className={classNames(`${style.container} auth-container`, {
            vb__layout__squaredBorders: isSquaredBorders,
            vb__layout__cardsShadow: isCardShadow,
            vb__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
        >
          <img src={bgImage} className={classNames(`${style.bgImage} bg-image`)} alt="" />
          <div className={style.container}>
            <div className={style.content}>
              <div className={style.formcon}>{children}</div>
              <div className="text-center mt-3 mb-3 font-size-14">
                <img className={style.logo} src="resources/images/logo.svg" alt="" />
                <div className={style.links}>
                  <a
                    href={`${process.env.REACT_APP_MAIN_URL}/terms`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms
                  </a>
                  <div className={style.dot} />
                  <a
                    href={`${process.env.REACT_APP_MAIN_URL}/privacy`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
                <div className="font-size-12">
                  {' '}
                  {formatMessage({ id: 'text.copyright' }, { appName })}{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(injectIntl(AuthLayout)))
