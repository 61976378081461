import apiClient from 'services/axios'

const BASE_URL1 = 'business'
const BASE_URL2 = 'support-user'
const BASE_URL3 = 'subscription'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL1}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function assignaccmanager(payload) {
  console.log('api call', payload)
  return apiClient
    .post(`${BASE_URL1}/${payload?.business_id}/support-user/${payload.account_manager}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function changebill(payload) {
  return apiClient
    .put(`${BASE_URL3}/discount`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function loadaccountmanager() {
  return apiClient
    .get(`${BASE_URL2}/account-manager`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function changebillplan(payload) {
  return apiClient
    .put(`${BASE_URL3}/billing-type`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addnotes(payload) {
  return apiClient
    .post(`${BASE_URL1}/notes`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
