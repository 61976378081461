import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import getUserMenuData from 'services/menu'
import actions from './actions'
import { selectCanAccessSetup } from '../user/selectors'

export function* SET_DATA() {
  const menuData = yield call(getUserMenuData)
  const canAccessSetup = yield select(selectCanAccessSetup)
  if (!canAccessSetup) menuData.splice(menuData.map((o) => o.key).indexOf('manage-user'), 1)
  yield put({
    type: actions.SET_STATE,
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([takeLatest(actions.SET_DATA, SET_DATA), SET_DATA()])
}
