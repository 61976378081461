import apiClient from 'services/axios'

const BASE_URL = 'support-user'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function update(staffId, payload) {
  return apiClient
    .put(`${BASE_URL}/${staffId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function remove(staffId, payload) {
  return apiClient
    .delete(`${BASE_URL}/${staffId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
