const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_ERROR_STATE: 'user/SET_ERROR_STATE',
  RESET_STATE: 'user/RESET_STATE',
  LOGIN: 'user/LOGIN',
  RECOVER_PASSWORD: 'user/RECOVER_PASSWORD',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  LOGOUT: 'user/LOGOUT',
  CLEAR_USER: 'user/CLEAR_USER',

  
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
}

export default actions
